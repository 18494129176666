// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./QaBatch.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import Divider from "@mui/material/Divider";
import { DataGrid } from '@mui/x-data-grid';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';

import AppScreens from '../../app-screens.json'

const QaBatch = () => {
    const dispatch = useDispatch()

    const [BatchData, setBatchData] = useState([])
    const [SelectedSaleBatch, setSelectedSaleBatch] = useState([])

    const [OrderNo, setOrderNo] = useState("")
    const [CustomerName, setCustomerName] = useState("")
    const [OrderItems, setOrderItems] = useState(Array(100).fill(""))


    const fetchBatch = async () => {
        try {
            const res = await axios.get(`/v1/erp/dispatch/latest`)
            if (res.status == 200) {
                console.log("QA Batch Fetched!");
                let saleBatchData = []
                let id = 1;
                res.data.data.forEach((batch) => {
                    batch.sheetStrippings[0].storageLocations.map((location) => {
                        saleBatchData.push({
                            id: id++,
                            batchNo: batch.batchNo,
                            readyAt: batch.sheetCuringDoneAt,
                            batchId: batch.id,
                            ...location,
                        })
                    })
                })
                setBatchData(saleBatchData)
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    const createSaleOrder = async () => {
        try {
            let order = []
            let EmprtyError = false
            SelectedSaleBatch.map((batch) => {
                if (OrderItems[batch-1] == "") {
                    // eslint-disable-next-line no-throw-literal
                    throw { response: { data: { message: `Please fill ${BatchData[batch - 1].batchNo} batch count`}}}
                }
                order.push({
                    ...BatchData[batch-1],
                    batchId: BatchData[batch-1].batchId,
                    count: OrderItems[batch-1]
                })
            })
            console.log(order);
            console.log(OrderItems);
            const res = await axios.post(`/v1/erp/dispatch/sale`, { 
                orderNo: OrderNo, 
                customerName: CustomerName, 
                customeDetails: {}, 
                orderItems: order
            })
            if (res.status == 200) {
                await fetchBatch()
                toast.success("Sale Order Created!")
                setSelectedSaleBatch([])
                setOrderNo("")
                setCustomerName("")
                setOrderItems(Array(100).fill(""))
                fetchBatch()
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            console.log(e);
            toast.error("Error")
        }

    }

    useEffect(() => {
        fetchBatch()
    }, [])

    // useEffect(() => {
    //     console.log(SelectedSaleBatch);
    //     const Sbmap = SelectedSaleBatch.map((batch) => BatchData[batch-1])
    //     console.log(Sbmap);
    // }, [SelectedSaleBatch])

    const dateRenderer = (dateparam) => {
        const date = new Date(dateparam);
        const formattedDate = `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear().toString().slice(-2)}`;        return formattedDate;
    }

    const batchColumns = [
        {
            field: 'readyAt',
            headerName: '🟢 on',
            width: 90,
            renderCell: (params) => {
                return <div>{dateRenderer(params.value)}</div>;
            }
        },
        {
            field: 'batchNo',
            headerName: 'Bt #'
        },
        {
            field: 'size',
            headerName: 'Size'
        },
        {
            field: 'count',
            headerName: 'Count',
            width: "90",
        },
        {
            field: 'bay',
            headerName: 'Bay'
        },
    ]

    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 2, margin: 1, justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "95%",
                        paddingBottom: 2,
                    }}
                >
                    <Typography component="h1" variant="h4" sx={{ mt: 1, mb: 1 }}>
                        {AppScreens.QA_BATCH.title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginY: 3 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} my={1}>
                                <TextField
                                    fullWidth
                                    label="Order No."
                                    variant="outlined"
                                    type='text'
                                    sx={{ width: "100%" }}
                                    value={OrderNo}
                                    onChange={(e) => { setOrderNo(e.target.value) }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} my={1}>
                                <TextField
                                    fullWidth
                                    label="Customer Name"
                                    variant="outlined"
                                    type='text'
                                    sx={{ width: "100%" }}
                                    value={CustomerName}
                                    onChange={(e) => { setCustomerName(e.target.value) }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} my={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign="left" mb={1}>
                                <Typography component="h6" variant="h6">
                                    Select batch:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={4}>
                                <DataGrid
                                    rows={BatchData}
                                    columns={batchColumns}
                                    sx={{ minHeight: "15vh" }}
                                    disableColumnFilter
                                    disableColumnResize
                                    disableColumnMenu
                                    checkboxSelection
                                    onRowSelectionModelChange={(selection) => {
                                        setSelectedSaleBatch(selection);
                                    }}
                                    rowSelectionModel={SelectedSaleBatch}
                                    autosizeOnMount
                                />
                            </Grid>
                            {SelectedSaleBatch.map((batch) => BatchData[batch - 1]).map((batch, index) => {
                                return (
                                    <Grid container key={index}>
                                    <Grid item xs={12} sm={12} mt={2} mb={4}>
                                        <Typography component="h6" variant="h6" mb={2}>
                                            {dateRenderer(batch.readyAt)} | Bt #: {batch.batchNo} | Size: {batch.size} | Bay: {batch.bay}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            label="Count"
                                            variant="outlined"
                                            type='text'
                                            sx={{ width: "100%" }}
                                            value={OrderItems[batch.id - 1]}
                                            onChange={(e) => { setOrderItems(o => o.map((item, index) => index == batch.id - 1 ? e.target.value : item)) }}
                                            onFocus={event => {
                                                event.target.select();
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} sm={12} mt={5}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} pr={1} mb={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => { createSaleOrder() }}
                                    sx={{ mt: 3, mb: 2, }}
                                    disabled={OrderNo == "" || CustomerName == "" || SelectedSaleBatch.length == 0}
                                >
                                    Create Sale
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Card>
        </PageContainer>
    );
}

export default QaBatch