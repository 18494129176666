/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increment, decrement, setCount } from './StrippingBatchSlice';
import styles from "./StrippingBatch.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';

import AppScreens from '../../app-screens.json'

const DEFAULT_MAX_SHEETS = 100

const StrippingBatch = () => {
    const dispatch = useDispatch()
    const [Mode, setMode] = useState("m")

    const [Dt, setDt] = React.useState(new Date());
    const [BatchNo, setBatchNo] = React.useState("");
    const [ManualCount, setManualCount] = React.useState(0);

    const [BatchData, setBatchData] = useState([])
    const [StartBatchData, setStartBatchData] = useState([])

    const [SheetLocations, setSheetLocations] = useState([])
    const [ShtBay, setShtBay] = useState(Array(DEFAULT_MAX_SHEETS).fill(""))
    const [ShtCount, setShtCount] = useState(Array(DEFAULT_MAX_SHEETS).fill(""))


    const resetValues = () => {
        setMode("m")
        setDt(new Date())
        setBatchNo("")
        setManualCount(0)
        setSheetLocations([])
        setShtBay(Array(DEFAULT_MAX_SHEETS).fill(""))
        setShtCount(Array(DEFAULT_MAX_SHEETS).fill(""))
    }
    

    const fetchBatch = async () => {
        try {
            const res = await axios.get(`/v1/erp/stripping/latest`)
            const res2 = await axios.get(`/v1/erp/stripping/latest/start`)
            if (res.status == 200) {
                console.log("Stripping Batch Fetched!");
                setBatchData(res.data.data)
                setStartBatchData(res2.data.data)
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            console.log(e);
            toast.error("Error")
        }
    }

    const submitStart = async () => {
        if (BatchNo == "") {
            toast.error("Batch No. is required")
            return
        }
        try {
            const res = await axios.post(`/v1/erp/stripping/start`, {
                batchNo: BatchNo,
                startTime: Dt
            })
            if (res.status == 200 && res.data.status == "ok") {
                toast.success("Batch started")
                resetValues()
                fetchBatch()
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    const submitEnd = async () => {
        if (BatchNo == "") {
            toast.error("Batch No. is required")
            return
        }
        try {
            const res = await axios.post(`/v1/erp/stripping/end`, {
                batchNo: BatchNo,
                endTime: Dt,
                manualCounter: ManualCount,
                storageLocations: SheetLocations,
            })
            if (res.data.status == "created") {
                toast.success("Batch Ended")
                resetValues()
                setTimeout(() => { fetchBatch() }, 1000)
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    useEffect(() => {
        fetchBatch()
    }, [])


    const BatchStart = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
            }}
        >
            <Typography component="h1" variant="h4" sx={{ mt: 3, mb: 4 }}>
                {AppScreens.STRIPPING_BATCH.title}
            </Typography>
            <Grid container pb={3}>
                <Grid item xs={8} sm={8} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Batch Start Time"
                            value={dayjs(Dt)}
                            fullWidth
                            sx={{ width: "100%" }}
                            onChange={(v) => { setDt(new Date(v)) }}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={4} px={1} mb={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => { setDt(new Date()) }}
                        sx={{ height: "100%" }}
                    >
                        Now
                    </Button>
                </Grid>

                <Grid item xs={12} sm={12} mt={1} mb={2}>
                    <FormControl fullWidth>
                        <InputLabel id="select-Batch">Select Batch</InputLabel>
                        <Select
                            labelId="select-Batch"
                            value={BatchNo}
                            label="Batch No."
                            onChange={(e) => { setBatchNo(e.target.value) }}
                        >
                            {StartBatchData.map((item, index) => (
                                <MenuItem key={index} value={item.batchNo}>{item.batchNo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} pr={1} mb={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => { submitStart() }}
                        sx={{ mt: 3, mb: 2, }}
                        disabled={BatchNo == ""}
                    >
                        Start Batch
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} pl={1} mb={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color='secondary'
                        onClick={() => { resetValues() }}
                        sx={{ mt: 3, mb: 2, }}
                    >
                        Go Back
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )

    const BatchEnd = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90%",
            }}
        >
            <Typography component="h1" variant="h4" sx={{ mt: 1, mb: 2 }}>
                {AppScreens.STRIPPING_BATCH.title}
            </Typography>
            <Grid container pb={1}>
                <Grid item xs={12} sm={12} mt={1} mb={2}>
                    <FormControl fullWidth>
                        <InputLabel id="select-Batch">Select Batch</InputLabel>
                        <Select
                            labelId="select-Batch"
                            value={BatchNo}
                            label="Batch No."
                            onChange={(e) => { setBatchNo(e.target.value) }}
                        >
                            {BatchData.map((item, index) => (
                                <MenuItem key={index} value={item.batchNo}>{item.batchNo}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} textAlign="left" mt={2} mb={1}>
                    <Divider sx={{ mb: 2 }} />
                    <Typography component="h5" variant="h5">
                        Sheet Locations:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} textAlign="left">
                    {SheetLocations.map((item, index) => (
                        <Chip
                            key={index}
                            color="primary"
                            label={<>
                                <b>Size: </b> {item.size} &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Bay: </b> {item.bay} &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                <b>Count: </b> {item.count}
                            </>}
                            size="large"
                            sx={{ my: 1, mx: 0.5 }}
                            onDelete={() => {
                                setSheetLocations(ss => ss.filter((_, i) => i !== index));
                                setManualCount(c => { return c - item.count });
                            }}
                            // deleteIcon={<DeleteIcon sx={{ p:0.5, backgroundColor: "#ffffff4D", color: "primary", borderRadius:100 }} />}
                        />
                    ))}
                </Grid>
                { BatchNo? <>
                {BatchData.find(item => item.batchNo == BatchNo).sheetCuttings[0].sheetSizes.map((item, index) => {
                    return (
                        <Grid container key={index} mt={4}>
                            <Grid item xs={12} sm={12} textAlign="left" mt={1} mb={1}>
                                <Typography component="p" variant="p">
                                    Location of <b>Sheet Size:</b> {item.size}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} pr={1}>
                                <TextField
                                    fullWidth
                                    label="Bay"
                                    variant="outlined"
                                    type='text'
                                    sx={{ width: "100%" }}
                                    value={ShtBay[index]}
                                    onChange={(e) => { setShtBay(ShtBay.map((item, i) => i == index ? e.target.value : item)) }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} pr={1}>
                                <TextField
                                    fullWidth
                                    label="Count"
                                    variant="outlined"
                                    type='number'
                                    sx={{ width: "100%" }}
                                    value={ShtCount[index]}
                                    onChange={(e) => { setShtCount(ShtCount.map((item, i) => i == index ? e.target.value : item)) }}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => { 
                                        setSheetLocations(ss => [...ss, { bay: ShtBay[index], count: ShtCount[index], size: item.size, cutCount: item.count }]); 
                                        setManualCount(c => { return c + parseInt(ShtCount[index]) }); 
                                        setShtBay(ShtBay.map((item, i) => i == index ? "" : item));
                                        setShtCount(ShtCount.map((item, i) => i == index ? "" : item));
                                    }}
                                    sx={{ height: "100%", fontSize: 25 }}
                                >
                                    +
                                </Button>
                            </Grid>
                        </Grid>
                    )
                })}
                    <Grid item xs={12} sm={12} textAlign="center" mt={4} mb={1}>
                        <Typography component="h6" variant="h6">
                            Total Stripping Count: {ManualCount}
                        </Typography>
                    </Grid>
                </>:<>
                    <Grid item xs={12} sm={12} textAlign="center" mt={1} mb={1}>
                        <Typography component="p" variant="p">
                            Select Batch to add Sheet Locations
                        </Typography>
                    </Grid>
                </>}
                <Grid item xs={12} sm={12} my={2}>
                    <Divider sx={{ mb: 2 }} />
                </Grid>
                <Grid item xs={8} sm={8} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Batch End Time"
                            value={dayjs(Dt)}
                            fullWidth
                            sx={{ width: "100%" }}
                            onChange={(v) => { setDt(new Date(v)) }}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={4} px={1} mb={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => { setDt(new Date()) }}
                        sx={{ height: "100%" }}
                    >
                        Now
                    </Button>
                </Grid>
                
                <Grid item xs={6} sm={6} pr={1} mt={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => { submitEnd() }}
                        disabled={BatchNo == "" || ManualCount == 0}
                        sx={{ mb: 2, }}
                    >
                        End Batch
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} pl={1} mt={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color='secondary'
                        onClick={() => { resetValues() }}
                        sx={{ mb: 2, }}
                    >
                        Go Back
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 3, margin: 1, justifyContent: "center" }}>
                {Mode == 'm' && <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "80%",
                        paddingBottom: 2,
                    }}
                >
                    <Typography component="h1" variant="h4" sx={{ mt: 3, mb: 1 }}>
                        {AppScreens.STRIPPING_BATCH.title}
                    </Typography>

                    <BadgeIcon
                        label={BatchData.length > 0 ? `${BatchData[0].batchNo}` : "No Batch Running"}
                        icon={
                            <ContentCopyIcon
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "unset",
                                }}
                            />
                        }
                        online={BatchData.length > 0}
                        onClick={() => { fetchBatch() }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={() => { setMode("s"); setDt(new Date()) }}
                        sx={{ mt: 5, mb: 2, pt: 5, pb: 5, fontSize: 29 }}
                    >
                        Start
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        onClick={() => { fetchBatch(); setMode("e"); setDt(new Date()) }}
                        sx={{ mt: 3, mb: 2, pt: 5, pb: 5, fontSize: 29 }}
                    >
                        End
                    </Button>
                </Box>}
                {Mode == 's' && BatchStart()}
                {Mode == 'e' && BatchEnd()}
            </Card>
        </PageContainer>
    );
}


const BadgeIcon = (props) => {

    const StyledOnlineBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: "#44b700",
            color: "#44b700",
            //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                animation: "ripple 1.2s infinite ease-in-out",
                border: "1px solid currentColor",
                content: '""',
            },
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1,
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0,
            },
        },
    }));

    const StyledOfflineBadge = styled(Badge)(({ theme }) => ({
        "& .MuiBadge-badge": {
            backgroundColor: "#ba000d",
            color: "#ba000d",
            //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                animation: "ripple 1.2s infinite ease-in-out",
                border: "1px solid currentColor",
                content: '""',
            },
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1,
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0,
            },
        },
    }));

    return (
        <Chip
            size="medium"
            sx={{ margin: 1 }}
            onClick={props.onClick}
            avatar={
                props.online ? (
                    <StyledOnlineBadge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        variant="dot"
                    >
                        {props.icon}
                    </StyledOnlineBadge>
                ) : (
                    <StyledOfflineBadge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        variant="dot"
                    >
                        {props.icon}
                    </StyledOfflineBadge>
                )
            }
            label={props.label}
        />
    );
}


export default StrippingBatch