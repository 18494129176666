// import lib(s)
import React, { useEffect, useState } from 'react';
import styles from "./NotFound.module.css";
import Lottie from "lottie-react";
import nf from './nf.json'


import PageContainer from 'components/PageContainer';

const NotFound = () => {
    

    return (
        <PageContainer>
            <br/><br/>
            <br/><br/>
            <center>
                <Lottie animationData={nf} loop={true} />
            </center>
        </PageContainer>
    );
}

export default NotFound