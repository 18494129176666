// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increment, decrement, setCount } from './MenuBarSlice';
import styles from "./MenuBar.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

import { useNavigate } from 'react-router-dom';
import { setUser } from 'container/App/App.slice';
import AppScreens from 'app-screens.json'


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    px: 5,
    pb: 5,
};

const MenuBar = () => {    
    const [Open, setOpen] = useState(false)
    const user = useSelector((state) => state.App.user);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    let greeting = "🌞"
    if (new Date().getHours() > 6 && new Date().getHours() < 11) {
        greeting = "🌞"
    } else if (new Date().getHours() < 17) {
        greeting = "🌤️"
    } else if (new Date().getHours() < 20) {
        greeting = "🌆"
    } else {
        greeting ="🌙"
    }

    const logout = () => {
        dispatch(setUser({}))
        navigate(AppScreens.LOGIN.route)
    }


    return (
        <>{Object.keys(user).length > 0 && <React.Fragment>
            <Card sx={{ margin: 1, marginBottom: 3, borderRadius: 10, }}>
                <Grid container p={1}>
                    <Grid item xs={2} sm={2} className={styles.leftSide}>
                        <Typography variant="h4" component="h4" align="center" className={styles.leftSideText}>
                            {greeting}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} className={styles.leftSide} >
                        <Typography variant="h6" component="h6" align="left"  className={styles.leftSideText}>
                            {user && user.name ? user.name : "User"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} className={styles.rightSide}>
                        <Button variant='outlined' onClick={()=>setOpen(true)} sx={{ color: "#fff", borderColor: "#fff", padding: 1, minHeight: 0, minWidth: 0, marginX: 1}}>
                            <MenuRoundedIcon />
                        </Button>
                        <Divider orientation="vertical" sx={{ m: 1 }} />
                        <Button variant='outlined' onClick={() => logout()} sx={{ color: "#fff", border: "none", padding: 1, minHeight: 0, minWidth: 0, }}>
                            <LogoutRoundedIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Modal
                open={Open}
                onClose={()=>{setOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Grid container p={1} >
                        <Grid item xs={12} sm={12} align="center" my={1}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Menu
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} align="center" my={1}>
                            <Button variant='outlined' onClick={() => { setOpen(false); navigate(AppScreens[user.startScreen].route)}} fullWidth sx={{ color: "#fff", borderColor: "#fff", padding: 1, minHeight: 0, minWidth: 0, }}>
                                <HomeRoundedIcon />&nbsp;&nbsp; Home
                            </Button>
                        </Grid>
                        {user?.accessControl?.menuItems.map((menuItem, index) => {
                            return (
                                <Grid item xs={12} sm={12} align="center" my={1} key={index}>
                                    <Button variant='outlined' onClick={() =>{ setOpen(false); navigate(AppScreens[menuItem].route)}} fullWidth sx={{ color: "#fff", borderColor: "#fff", padding: 1, minHeight: 0, minWidth: 0, }}>
                                        {AppScreens[menuItem].title}&nbsp;&nbsp;<KeyboardDoubleArrowRightRoundedIcon />
                                    </Button>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12} sm={12} align="center" my={1}>
                            <Button variant='outlined' onClick={()=>setOpen(false)} color='error' fullWidth sx={{padding: 1, minHeight: 0, minWidth: 0, }}>
                                &times; Close Menu
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>}</>
    );
}

export default MenuBar