// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./Landing.module.css";

import PageContainer from 'components/PageContainer';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { setUser } from 'container/App/App.slice';
import AppScreens from 'app-screens.json'


const Landing = () => {
    const user = useSelector((state) => state.App.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const fetchRefreshToken = async () => {
        if (user.refreshToken) {
            try {
                const promise = axios.post("/v1/auth/refresh", { refreshToken: user.refreshToken })

                toast.promise(promise, {
                    loading: 'Loading...',
                    success: <b>Welcome Back {user.name ? user.name : "User"}!</b>,
                    error: <b>Session Expired.</b>
                })

                const response = await promise
                if (response.status === 200) {
                    if (response.data.startScreen) {
                        dispatch(setUser(response.data));
                        navigate(AppScreens[response.data.startScreen].route)
                    } else {
                        toast.error('Account disabled, please contact support');
                    }
                }
            } catch (error) {
                navigate('/login')
            }

        }
    }
    useEffect(() => {
        fetchRefreshToken()
        if (Object.keys(user).length == 0) {
            const nav = () => {
                setTimeout(() => {
                    toast("Welcome to Grea ERP!")
                }, 100)
            }
            nav()
            navigate('/login')
        }
    }, [])
    return (
        <PageContainer>
            <center>
                <CircularProgress size={100} sx={{mt: 10}} />
            </center>
        </PageContainer>
    );
}

export default Landing