// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./PageContainer.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';
import Divider from '@mui/material/Divider';

import MenuBar from 'components/MenuBar';

const PageContainer =( {children} ) => {  
    
    return (
        <React.Fragment >
            <Container component="main" maxWidth="md" sx={{ paddingTop: 4 }}>
                <Grid container p={1} sx={{ marginTop: 1, marginBottom: 2 }}>
                    <Grid item xs={12} sm={12} p={2} alignContent="center" alignItems="center" textAlign="center">
                        <img style={{ width: "80%", height: "auto" }} src={logo} alt='Grea ERP Logo' />
                    </Grid>
                </Grid>
                <CssBaseline />
                <MenuBar />
                {children}
            </Container>
        </React.Fragment >
    );
}

export default PageContainer