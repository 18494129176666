/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from "react";
import styles from "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from "container/Landing";
import NotFound from "container/NotFound";
// import Home from "container/Home";
import Error from "container/Error";
import Dash from "container/Dash";
import AdminBatch from "container/AdminBatch";
import CuttingBatch from "container/CuttingBatch";
import StrippingBatch from "container/StrippingBatch";
import CuringBatch from "container/CuringBatch";
import QaBatch from "container/QaBatch";
import LoadingBatch from "container/LoadingBatch";
import Login from "container/Login";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider } from 'react-redux';
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';


const themeColors = ["#4ED8F6", "#1150EE", "#23006C", "#000000"]

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: themeColors[1],
      light: themeColors[0],
      dark: themeColors[2],
    },
    secondary: {
      main: themeColors[0],
      light: themeColors[1],
      dark: themeColors[2],
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: < Error />,
  },
  {
    path: "/dash",
    element: <Dash />,
    errorElement: < Error />,
  },
  {
    path: "/admin-batch",
    element: <AdminBatch />,
    errorElement: < Error />,
  },
  {
    path: "/cutting-batch",
    element: <CuttingBatch />,
    errorElement: < Error />,
  },
  {
    path: "/stripping-batch",
    element: <StrippingBatch />,
    errorElement: < Error />,
  },
  {
    path: "/curing-batch",
    element: <CuringBatch />,
    errorElement: < Error />,
  },
  {
    path: "/qa-batch",
    element: <QaBatch />,
    errorElement: < Error />,
  },
  {
    path: "/loading-batch",
    element: <LoadingBatch />,
    errorElement: < Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: < Error />,
  },
  {
    path: "*",
    element: <NotFound />,
    errorElement: < Error />,
  },
]);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

try {
  let localUserStore = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).App);
  if (localUserStore.user.accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localUserStore.user.accessToken}`;
  }
} catch (e) {
  console.log(e);
  console.log("Error parsing local storage");
}

// axios.interceptors.request.use(request => {
//   console.log(request);
//   // Edit request config
//   return request;
// }, error => {
//   console.log(error);
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
//   console.log(response);
//   return response;
// }, error => {

//   const navigate = useNavigate();
//   console.log(error);
//   return Promise.reject(error);
// });

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.localStorage.setItem('redirect', window.location.href);
      window.location.href = '/login?e=1';
    }
    return Promise.reject(error);
  });

const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={darkTheme}>
            <RouterProvider router={router} />
        </ThemeProvider>
        <Toaster 
          toastOptions={{
            icon: '📢',
            duration: 3000,
            style: {
              background: themeColors[3],
              color: '#fff',
              borderRadius: '10px',
              border: `2px solid ${themeColors[0]}`,
            },
            success: {
              // tick emoji
              icon: '✅',
              style: {
                border: `2px solid ${green[500]}`,
              }
            },
            error: {
              icon: '⛔',
              style: {
                border: `2px solid ${red[500]}`,
              }
            }
          }}
        />
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
};

export default App;
