/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increment, decrement, setCount } from './CuringBatchSlice';
import styles from "./CuringBatch.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import Divider from "@mui/material/Divider";
import { DataGrid, renderActionsCell, renderBooleanCell } from '@mui/x-data-grid';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';

import AppScreens from '../../app-screens.json'

const DEFAULT_MAX = 100

const CuringBatch = () => {
    const dispatch = useDispatch()

    const [BatchData, setBatchData] = useState([])

    const [SelectedBatch, setSelectedBatch] = useState({})
    const [SelectedLots, setSelectedLots] = useState([])

    const [ReportLots, setReportLots] = useState({})

    const [CheckedLocationsCount, setCheckedLocationsCount] = useState([])
    
    const resetValues = () => {
        setSelectedBatch({})
        setSelectedLots([])
        setCheckedLocationsCount([])
        setReportLots({})
    }
    

    const fetchBatch = async () => {
        try {
            const res = await axios.get(`/v1/erp/curing/latest`)
            if (res.status == 200) {
                console.log("Curing Batch Fetched!");
                setBatchData(res.data.data)
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    const submitInspection = async () => {
        try {
            // ReportLost = {id: {bay, size, count, cutCount, damage, reason}}
            // find damagedSheetCount by summing up all damage values
            const damagedSheetCount = Object.keys(ReportLots).reduce((acc, lotId) => {
                return acc + parseInt(ReportLots[lotId].damage)
            }, 0)

            const res = await axios.post(`/v1/erp/curing/inspect`, {
                batchNo: SelectedBatch.batchNo,
                damagedSheets: ReportLots,
                damagedSheetCount,
            })
            if (res.status == 200) {
                await fetchBatch()
                toast.success("Inspection Done!")
                resetValues()
                fetchBatch()
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    
    }

    useEffect(() => {
        fetchBatch()
    }, [])

    const batchColumns = [
        {
            field: 'sheetCuringStartedAt',
            headerName: 'Started',
            // width: 95,
            renderCell: (params) => {
                const date = new Date(params.value);
                const formattedDate = `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear().toString().slice(-2)}`;                return <div>{formattedDate}</div>;
            }
        },
        {
            field: 'batchNo',
            headerName: 'Bt #' 
        },
        { 
            field: 'sheetCuringInspectionCounter', 
            headerName: 'Day',
            // width: 75,
            renderCell: (params) => {
                const value = parseInt(params.value) + 1;
                return <div>{value}</div>;
            }
        },
    ]

    const sheetLocationColumns = [
        {
            field: 'bay',
            headerName: 'Bay',
        },
        {
            field: 'size',
            headerName: 'Size'
        },
        {
            field: 'count',
            headerName: 'Count',
            width: "90",
        },
        {
            field: 'cutCount',
            headerName: '❌',
            width: 155,
            padding: 0,
            margin:0,
            renderCell: (params) => {
                const accessor = params.row.id
                // const accessor = `${params.row.bay}=|=${params.row.size}=|=${params.row.count}`
                return (
                    <Button 
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setReportLots(x => { return { ...x, [accessor]: {...params.row, damage:0, reason: ""} }})
                        }}
                    > 
                    Report damage
                    </Button>
                )
            }
        }
    ]

    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 2, margin: 1, justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "95%",
                        paddingBottom: 2,
                    }}
                >
                    <Typography component="h1" variant="h4" sx={{ mt: 1, mb: 1 }}>
                        {AppScreens.CURING_BATCH.title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginY: 3 }}>
                        {Object.keys(SelectedBatch).length == 0 ? (<>
                        <DataGrid
                            rows={BatchData}
                            columns={batchColumns}
                            sx={{minHeight: "15vh"}}
                            // density="compact"
                            onRowClick={(params) => {
                                if (params.row.sheetStrippings && params.row.sheetStrippings.length > 0) {
                                    setSelectedBatch(params.row)
                                    // setSelectedLots(params.row.sheetStrippings[0].storageLocations.map((location, index) => ({
                                    //     ...location,
                                    //     id: index
                                    // })))
                                    setSelectedLots(params.row.sheetLot)
                                } else {
                                    toast.error("No Sheet Stripping Data Found")
                                }
                            }}
                            disableColumnFilter
                            disableColumnResize
                            disableColumnMenu
                            autosizeOnMount
                        />
                        </>) : (<>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h6" variant="h6" sx={{ mb: 2 }}>
                                    <table>
                                        <tr>
                                            <td style={{fontWeight:750}}>Batch No:&nbsp;&nbsp;</td>
                                            <td>{SelectedBatch.batchNo}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:750}}>Started:</td>
                                            <td>{SelectedBatch.sheetCuringStartedAt && new Date(SelectedBatch.sheetCuringStartedAt).toLocaleDateString('en-IN')}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:750}}>Day:</td>
                                            <td>{parseInt(SelectedBatch.sheetCuringInspectionCounter) + 1}</td>
                                        </tr>
                                    </table>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={2}>
                                <DataGrid
                                    rows={SelectedLots}
                                    columns={sheetLocationColumns}
                                    sx={{ minHeight: "15vh" }}
                                    // density="compact"
                                    disableColumnFilter
                                    disableColumnResize
                                    disableColumnMenu
                                    checkboxSelection
                                    onRowSelectionModelChange={(selection) => {
                                        setCheckedLocationsCount(selection);
                                    }}
                                    disableRowSelectionOnClick
                                    rowSelectionModel={CheckedLocationsCount}
                                    autosizeOnMount
                                />
                            </Grid>
                            {Object.keys(ReportLots).length > 0 && <Grid item xs={12} sm={12} mb={2}>
                                <Typography component="h5" variant="h5" sx={{ mt:4 , mb: 3 }}>
                                    Report damages on:
                                </Typography>
                                <Grid container spacing={1}>
                                    {Object.keys(ReportLots).map((lotId, index) => {
                                        return (
                                            <>
                                                <Grid item xs={9} sm={9}>
                                                    <Typography component="h6" variant="h6" sx={{ mb: 2 }}>
                                                        <b>Bay:</b> {ReportLots[lotId].bay} &nbsp;&nbsp;|&nbsp;&nbsp; <b>Size:</b> {ReportLots[lotId].size} 
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sm={3}>
                                                    <Button
                                                        color='error'
                                                        fullWidth
                                                        variant='outlined'
                                                        sx={{ width: "100%", }}
                                                        onClick={() => { setReportLots(x => { let y = { ...x }; delete y[lotId]; return y }) }}
                                                    >
                                                        ❌
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <TextField 
                                                        label="Dmg. No."
                                                        type="number"
                                                        value={ReportLots[lotId].damage}
                                                        onFocus={(e) => { e.target.select() }}
                                                        onChange={(e) => {
                                                            setReportLots(x => { return { ...x, [lotId]: {...ReportLots[lotId], damage: e.target.value} }})
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                    <TextField
                                                        label="Reason"
                                                        value={ReportLots[lotId].reason}
                                                        onChange={(e) => {
                                                            setReportLots(x => { return { ...x, [lotId]: {...ReportLots[lotId], reason: e.target.value} }})
                                                        }}
                                                    />
                                                </Grid >

                                            </>
                                        )
                                    })}
                                </Grid>
                            </Grid>}
                            <Grid item xs={6} sm={6} pr={1} mb={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => { submitInspection() }}
                                    sx={{ mt: 3, mb: 2, }}
                                    disabled={CheckedLocationsCount.length != SelectedLots.length}
                                >
                                    Inspection Done
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} pl={1} mb={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='secondary'
                                    onClick={() => { setSelectedBatch({}); setSelectedLots([]); }}
                                    sx={{ mt: 3, mb: 2, }}
                                >
                                    Go Back
                                </Button>
                            </Grid>
                        </Grid>
                        </>)}
                    </Box>
                </Box>
            </Card>
        </PageContainer>
    );
}

export default CuringBatch