/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./Login.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';


import PageContainer from 'components/PageContainer';
import { useNavigate } from "react-router-dom";
import { setUser } from 'container/App/App.slice';
import AppScreens from 'app-screens.json'

const Login = () => {
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [Loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const dispatch = useDispatch();

    const submit = (e) => {
        e.preventDefault();
        if (!Email.match(isValidEmail)) {
            toast.error('Invalid Email');
            return;
        }
        if (Password === "") {
            toast.error('Password is required');
            return;
        }

        const login = async () => {
            setLoading(true);
            try {
                const response = await axios.post('/v1/auth/login', { email: Email, password: Password });
                if (response.status === 200) {
                    if (response.data.startScreen) {
                        let responseData = { ...response.data };
                        if (!responseData.name) responseData.name = "User";
                        toast.success(`Hello ${responseData.name}!`);
                        dispatch(setUser(responseData));
                        // check if localstorage has redirect url
                        const redirectUrl = localStorage.getItem('redirect');
                        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
                        if (redirectUrl) {
                            localStorage.removeItem('redirect');
                            window.location.href = redirectUrl;
                        } else {
                            navigate(AppScreens[response.data.startScreen].route)
                        }
                        
                    } else {
                        toast.error('Account disabled, please contact support');
                    }
                }
            } catch (error) {
                toast.error('Login Failed');
            } finally {
                setLoading(false);
            }
        }
        login();
    }
    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 3, justifyContent: "center" }}>
                <Grid container pb={3} justifyContent='center'>
                    <Grid item xs={12} sm={12} p={1} mb={2} textAlign="center">
                        <Typography component="h1" variant="h4" sx={{ mt: 3, mb: 4 }}>
                            Login
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} p={1} mb={2}>
                        <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={Email}
                            disabled={Loading}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} p={1} mb={2}>
                        <TextField
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={Password}
                            disabled={Loading}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} px={7} mb={2} display='flex' justifyContent='center'>
                        <Button
                            fullWidth
                            size='large'
                            variant="contained"
                            onClick={submit}
                            disabled={Loading}
                            sx={{ mt: 3, mb: 2, }}
                        >
                            {Loading ? <CircularProgress size={24} /> : (<>Login</>)}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </PageContainer>   
    );
}

export default Login