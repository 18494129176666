// import lib(s)
import React from 'react';
import { useRouteError } from 'react-router-dom'
import Lottie from "lottie-react";
import errorLottie from './errlogo.json'

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import logo from '../../static/logo.png'

import CssBaseline from "@mui/material/CssBaseline";
import { Button } from '@mui/material';

const Error = () => {

    let error = useRouteError();
    console.log(error);
    return (
        
        <Container component="main" maxWidth="md" sx={{ paddingTop: 4 }}>
            <Grid container p={1} sx={{ marginTop: 1, marginBottom: 2 }}>
                <Grid item xs={12} sm={12} p={2} alignContent="center" alignItems="center" textAlign="center">
                    <img style={{ width: "80%", height: "auto" }} src={logo} alt='Grea ERP Logo' />
                </Grid>
            </Grid>
            <CssBaseline />
            <br/>
                <center>
                    <Lottie animationData={errorLottie} loop={true} />
                    <h1> Fatal Error!</h1>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            localStorage.setItem("persist:root", "");
                            localStorage.setItem("error", error);
                            window.location.reload();
                        }}
                    >
                        Reload App
                    </Button>
                </center>
        </Container>
    );
}

export default Error