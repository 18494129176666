/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import Divider from "@mui/material/Divider";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';



const Dash = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.App.user);

    const [Data, setData] = useState({})

    
    const fetchData = async () => {
        try {
            const res = await axios.get(`/v1/erp/dash/info`)
            if (res.status == 200) {
                console.log("Dash Data Fetched!");
                setData(res.data)
                console.log(res.data);
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    let formatter = Intl.NumberFormat('en', { notation: 'compact' });

    const CardPrinter = ({text, number}) => {
        return (
            <Card sx={{ display: "flex", flexDirection: "column", padding: 3, margin: 1, justifyContent: "center" }}>
                <Typography component="h1" variant="h2" sx={{ textAlign: "center" }}>
                    {formatter.format(number)}
                </Typography>
                <Typography component="h1" variant="h6" sx={{ textAlign: "center" }}>
                    {text}
                </Typography>
            </Card>
        )
    }
    return (        
        <PageContainer>
            <Typography component="h1" variant="h3" sx={{ mt: 5, mb: 2, textAlign: "center" }}>
                Welcome {user.name ? user.name : "User"}!
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <CardPrinter number={Data?.readyForDispatchCount} text="Ready For Dispatch Batches" />
                </Grid>
                {/* <Grid item xs={6}>
                    <CardPrinter number={Data?.batchCount} text="Total batches" />
                </Grid>
                <Grid item xs={6}>
                    <CardPrinter number={Data?.userCount} text="Total ActiveUsers" />
                </Grid> */}
                <Grid item xs={12}>
                <Typography component="h6" variant="h6" sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                    Active Batches in:
                </Typography>
                </Grid>
                <Grid item xs={4}>
                    <CardPrinter number={Data?.activeSheetCuttingCount} text="Prod." />
                </Grid>
                <Grid item xs={4}>
                    <CardPrinter number={Data?.activeSheetStrippingCount} text="Stripe" />
                </Grid>
                <Grid item xs={4}>
                    <CardPrinter number={Data?.activeCuringCount} text="Curing" />
                </Grid>
            </Grid>
        </PageContainer>
    );
}


export default Dash