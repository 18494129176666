// import lib(s)
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./LoadingBatch.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import Divider from "@mui/material/Divider";
import { DataGrid } from '@mui/x-data-grid';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';

import AppScreens from '../../app-screens.json'

const LoadingBatch = () => {
    const dispatch = useDispatch()

    const [BatchData, setBatchData] = useState([])
    const [SelectedSaleBatch, setSelectedSaleBatch] = useState({})


    const fetchBatch = async () => {
        try {
            const res = await axios.get(`/v1/erp/sales/created`)
            if (res.status == 200) {
                console.log("Sale Orders Fetched!");
                console.log(res.data.data);
                setBatchData(res.data.data)
            }
        } catch (e) {
            if (e.response) {
                toast.error(e.response.data.message)
                return
            }
            toast.error("Error")
        }
    }

    useEffect(() => {
        fetchBatch()
    }, [])

    useEffect(() => {
        console.log(SelectedSaleBatch);
    }, [SelectedSaleBatch])

    const dateRenderer = (dateparam) => {
        const date = new Date(dateparam);
        const formattedDate = `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear().toString().slice(-2)}`;
        return formattedDate;
    }

    const batchColumns = [
        {
            field: 'createdAt',
            headerName: '🟢 on',
            width: 90,
            renderCell: (params) => {
                return <div>{dateRenderer(params.value)}</div>;
            }
        },
        {
            field: 'orderNo',
            headerName: 'Odr #'
        },
        {
            field: 'customerName',
            headerName: 'Customer'
        },
    ]

    const batchDetailsColumns = [
        {
            field: 'readyAt',
            headerName: '🟢 on',
            width: 90,
            renderCell: (params) => {
                return <div>{dateRenderer(params.value)}</div>;
            }
        },
        {
            field: 'batchNo',
            headerName: 'Bt #'
        },
        {
            field: 'size',
            headerName: 'Size'
        },
        {
            field: 'count',
            headerName: 'Count',
            width: "90",
        },
        {
            field: 'bay',
            headerName: 'Bay'
        },
    ]


    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 2, margin: 1, justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "95%",
                        paddingBottom: 2,
                    }}
                >
                    <Typography component="h1" variant="h4" sx={{ mt: 1, mb: 1 }}>
                        {AppScreens.LOADING_BATCH.title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginY: 3 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} textAlign="left" mb={1}>
                                <Typography component="h6" variant="h6">
                                    Sale Orders:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <DataGrid
                                    rows={BatchData}
                                    columns={batchColumns}
                                    sx={{ minHeight: "15vh" }}
                                    disableColumnFilter
                                    disableColumnResize
                                    disableColumnMenu
                                    autosizeOnMount
                                    onRowClick={(row) => { setSelectedSaleBatch(row.row)}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} mt={4}>
                                <Divider />
                            </Grid>
                            {Object.keys(SelectedSaleBatch).length > 0 && (
                                <Grid container mt={4}>
                                    <Grid item xs={12} sm={12} textAlign="left" mb={1}>
                                        <Typography component="h6" variant="h6">
                                            Sale Order Details:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography component="p" variant="p">
                                            Customer Name: {SelectedSaleBatch.customerName}
                                        </Typography>
                                        <Typography component="p" variant="p">
                                            Order Number: {SelectedSaleBatch.orderNo}
                                        </Typography>
                                        <Typography component="p" variant="p">
                                            Order Created At: {dateRenderer(SelectedSaleBatch.createdAt)}
                                        </Typography>
                                        <Typography component="p" variant="p">
                                            Order Items:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} my={2}>
                                        <DataGrid
                                            rows={SelectedSaleBatch.orderItems}
                                            columns={batchDetailsColumns}
                                            sx={{ minHeight: "15vh" }}
                                            disableColumnFilter
                                            disableColumnResize
                                            disableColumnMenu
                                        />
                                    </Grid>
                            </Grid>
                            )}
                            <Grid item xs={12} sm={12} pr={1} mb={2}>
                                {/* <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => { }}
                                    sx={{ mt: 3, mb: 2, }}
                                >
                                    Create Sale
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Card>
        </PageContainer>
    );
}

export default LoadingBatch