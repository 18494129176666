/* eslint-disable */
// import lib(s)
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styles from "./AdminBatch.module.css";

import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from '@mui/material/CircularProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import Divider from "@mui/material/Divider";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, renderActionsCell, renderBooleanCell } from '@mui/x-data-grid';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-in';
import dayjs from 'dayjs';
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAsync from "hooks/useAsync";
import LastSeenFloating from "components/LastSeenFloating";
import logo from '../../static/logo.png'
import toast from 'react-hot-toast';
import axios from 'axios';

import PageContainer from 'components/PageContainer';
import MenuBar from 'components/MenuBar';

import AppScreens from '../../app-screens.json'
import { useSearchParams } from "react-router-dom";

import MUIColorsblue from '@mui/material/colors/blue';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

const DEFAULT_MAX = 100

const AdminBatch = () => {
    const dispatch = useDispatch()
    const targetRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams()
    const [BatchNo, setBatchNo] = useState("")

    const [Batches, setBatches] = useState([])
    const [BatchData, setBatchData] = useState({})

    const [Loading, setLoading] = useState(false)

    const search = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`v1/erp/admin/batch/search`, { batchNo : BatchNo })
            setBatches(response.data)
            if (response.data.length === 1) {
                fetchBatchData(response.data[0].batchNo)
            }
        } catch (error) {
            console.error(error)
            toast.error("Failed to search batch numbers")
        }
        setLoading(false)
    }

    const fetchBatchData = async (batchNumber) => {
        setLoading(true)
        try {
            const response = await axios.get(`v1/erp/admin/batch/${batchNumber}`)
            setBatchData(response.data.data)
        } catch (error) {
            console.error(error)
            toast.error(`"${batchNumber}" batch not found!`)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (searchParams.getAll("b"))
            setBatchNo(searchParams.getAll("b")[0])
    }, [])

    useEffect(() => {
        setSearchParams({ b: BatchNo })
    }, [BatchNo])

    return (
        <PageContainer>
            <Card sx={{ display: "flex", padding: 2, margin: 1, justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "95%",
                    }}
                >
                    <Grid container my={4}>
                        <Grid item xs={9} sm={9} mb={1}>
                            <TextField
                                fullWidth
                                label="Batch No."
                                sx={{ width: "100%" }}
                                value={BatchNo}
                                onChange={(e) => setBatchNo(e.target.value)}
                                onKeyUpCapture={(e) => { if (e.key === 'Enter') search() }}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} px={1} mb={1}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => { search()}}
                                sx={{ height: "100%" }}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} mb={4}>
                            {Batches.length > 0 ? (
                                <>{Batches.map((batch, index) => (
                                    <Chip
                                        key={index}
                                        label={batch.batchNo}
                                        color='primary'
                                        onClick={() => {
                                            setBatchNo(batch.batchNo)
                                            fetchBatchData(batch.batchNo)
                                        }}
                                        sx={{ margin: 0.3 }}
                                    />
                                ))}</>
                            ) : ( <Typography textAlign="center">Start by searching batch numbers</Typography> )}
                        </Grid>
                        {Loading && <Grid item xs={12} sm={12} m={10} alignContent="center" alignItems="center" textAlign="center">
                            <CircularProgress color="secondary" size={100} thickness={3} />
                        </Grid>}
                        {Object.keys(BatchData).length > 0 && <Grid item xs={12} ref={targetRef} sx={{backgroundColor:"#1e1e1e"}}>
                            <Grid item xs={12} mb={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} mb={2} textAlign="center">
                                <Typography variant="h6" textAlign="center"><Cb>{BatchData.batchNo}</Cb> - Batch Details</Typography>
                                {/* <Typography variant="p" textAlign="center"><Cb>Current Process:</Cb> {BatchData.currentProcess}</Typography> */}
                            </Grid>
                            <Grid item xs={12} mb={2} >
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td><Cb>Current Process:</Cb></td>
                                        <td>{BatchData.currentProcess}</td>
                                    </tr>
                                    <tr>
                                        <td><Cb>Created At:</Cb></td>
                                        <td>{new Date(BatchData.createdAt).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td><Cb>Last Updated:</Cb></td>
                                        <td>{new Date(BatchData.updatedAt).toLocaleString()}</td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={12}>
                                <Timeline>
                                    {/* Sheet Cutting */}
                                    {BatchData.sheetCuttingDone && <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            {new Date(BatchData.sheetCuttingDoneAt).toLocaleString()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            Production
                                        </TimelineContent>
                                    </TimelineItem>}

                                    {/* Sheet Stripping */}
                                    {BatchData.sheetStrippingDone && <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            {new Date(BatchData.sheetStrippingDoneAt).toLocaleString()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            Stripping
                                        </TimelineContent>
                                    </TimelineItem>}

                                    {/* Sheet Curing */}
                                    {BatchData.sheetCuringStartedAt && !BatchData.sheetCuringDone && <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            Day {BatchData.sheetCuringInspectionCounter+1}, <br/>
                                            {new Date(BatchData.sheetCuringLastInspectedAt).toLocaleDateString()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            Curing (On Going)
                                        </TimelineContent>
                                    </TimelineItem>}

                                    {/* Sheet Curing done */}
                                    {BatchData.sheetCuringDone && <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            {new Date(BatchData.sheetCuringDoneAt).toLocaleString()}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            Curing
                                        </TimelineContent>
                                    </TimelineItem>}

                                    {/* Current Step */}
                                    <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            {camelize(BatchData.currentProcess.replace("_", " ").toLowerCase())}
                                        </TimelineContent>
                                    </TimelineItem>

                                </Timeline>
                            </Grid>
                            {BatchData.sheetCuttingDone && <><Grid item xs={12} mb={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="h6" textAlign="center">Production Details</Typography>
                            </Grid>
                            <Grid item xs={12} mb={2} >
                                <Typography variant="p" sx={{ m: 1, whiteSpace: "pre", display: "block" }}>
                                    <b>Sheet Sizes:</b>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td><Cb>Size</Cb></td>
                                            <td><Cb>Count</Cb></td>
                                        </tr>
                                        {BatchData.sheetCuttings[0].sheetSizes.map((Ssz, index) => (
                                            <tr>
                                                <td>{Ssz.size}</td>
                                                <td>{Ssz.count}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </Typography>

                                <Typography variant="p" sx={{ m: 1, mt:3, whiteSpace: "pre", display: "block" }}>
                                    <Cb>Total Manual Count:</Cb> {BatchData.sheetCuttings[0].manualCounter}
                                </Typography>

                                <Typography variant="p" sx={{ m: 1, whiteSpace: "pre", display: "block" }}>
                                    <Cb>Total Sensor Count:</Cb> {BatchData.sheetCuttings[0].counter}
                                </Typography>
                            </Grid></>}
                            {BatchData.sheetStrippingDone && <><Grid item xs={12} mb={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="h6" textAlign="center">Stripping Details</Typography>
                            </Grid>
                            <Grid item xs={12} mb={2} >
                                <Typography variant="p" sx={{ m: 1, whiteSpace: "pre", display: "block" }}>
                                    <b>Lot Locations:</b>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td><Cb>Bay</Cb></td>
                                            <td><Cb>Size</Cb></td>
                                            <td><Cb>Count</Cb></td>
                                        </tr>
                                        {BatchData.sheetStrippings[0].storageLocations.map((Ssz, index) => (
                                            <tr>
                                                <td>{Ssz.bay}</td>
                                                <td>{Ssz.size}</td>
                                                <td>{Ssz.count}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </Typography>

                                <Typography variant="p" sx={{ m: 1, mt:3, whiteSpace: "pre", display: "block" }}>
                                    <Cb>Total Manual Count:</Cb> {BatchData.sheetStrippings[0].manualCounter}
                                </Typography>

                                <Typography variant="p" sx={{ m: 1, whiteSpace: "pre", display: "block" }}>
                                    <Cb>Total Sensor Count:</Cb> {BatchData.sheetStrippings[0].counter}
                                </Typography>
                            </Grid></>}
                            <Grid item xs={12} mb={2}>
                                <Divider />
                            </Grid>
                        </Grid>}
                        {Object.keys(BatchData).length > 0 && <Grid item xs={12} mb={2}>
                            <Button variant="contained" fullWidth onClick={() => generatePDF(targetRef, { filename: `${BatchData.batchNo}_${new Date().getTime()}.pdf` })}>
                                Download as PDF
                            </Button>
                        </Grid>
                        }
                    </Grid>
                </Box>
            </Card>
        </PageContainer>
    );
}

const Cb = ({ children }) => (
    <b style={{ color: `${MUIColorsblue[500]}` , fontWeight: "bold" }}>{children}</b>
);

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return word.toUpperCase();
    }).replace(/\s+/g, ' ');
}

export default AdminBatch